
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    iconType: {
      type: String,
      default: "fas",
    },
    iconMeta: {
      type: String,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-secondary text-right"
}
const _hoisted_2 = { class: "text-center p-2 w-40 truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SolidButtonVue = _resolveComponent("SolidButtonVue")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_EmptyButtonVue = _resolveComponent("EmptyButtonVue")!

  return (_ctx.isLoggedIn)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.user?.name) + "!", 1),
        (_ctx.keepExpanded)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: "/dashboard"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SolidButtonVue, {
                  text: 'dashboard',
                  class: "w-32 mt-2 mb-1"
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.keepExpanded)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout?.()))
            }, [
              _createVNode(_component_EmptyButtonVue, {
                text: 'logout',
                class: "w-32"
              })
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.login?.()))
      }, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_SolidButtonVue, {
              key: 0,
              icon: 'spinner',
              iconMeta: 'fa-pulse',
              text: 'login',
              class: "w-16"
            }))
          : (_openBlock(), _createBlock(_component_SolidButtonVue, {
              key: 1,
              text: 'login',
              class: "w-16"
            }))
      ]))
}
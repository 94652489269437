
import { useAuth0 } from "@/services/auth0-plugin";

import EmptyButtonVue from "../particles/EmptyButton.vue";
import SolidButtonVue from "../particles/SolidButton.vue";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    keepExpanded: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SolidButtonVue,
    EmptyButtonVue,
  },
  setup() {
    const auth0 = useAuth0();
    let isLoggedIn = auth0?.isAuthenticated;
    let user = auth0?.user;
    let login = auth0?.login;
    let logout = auth0?.logout;
    let isLoading = auth0?.isLoading;
    let getToken = auth0?.getAccessToken;

    return {
      user,
      login,
      logout,
      isLoggedIn,
      isLoading,
      getToken,
    };
  },
});

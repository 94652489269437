
import { defineComponent } from "vue";

export default defineComponent({
  props: ["modelValue", "isError"],
  emits: ["update:modelValue"],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});

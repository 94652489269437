import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-red-700 bg-red-300 rounded-lg p-2 items-center flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.text)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'exclamation'],
          class: "mx-2"
        }),
        _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
      ]))
    : _createCommentVNode("", true)
}
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "font-secondary inline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-full rounded-xl overflow-hidden truncate p-2 text-center hover:shadow hover:bg-primary", 
      _ctx.isSelected
        ? 'bg-primary text-primary-light'
        : 'bg-primary-light text-primary cursor-pointer hover:text-white'
    ])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: [`${_ctx.iconType}`, `${_ctx.icon}`],
          class: _normalizeClass(`${_ctx.iconMeta}`)
        }, null, 8, ["icon", "class"]))
      : _createCommentVNode("", true),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true)
  ], 2))
}
import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      type: "text",
      class: _normalizeClass(["w-40 bg-transparent inline", _ctx.isError ? 'input-error' : 'input']),
      spellcheck: "false",
      autofocus: "",
      "aria-label": "Form Code",
      name: "formcode"
    }, null, 2), [
      [_vModelText, _ctx.inputValue]
    ]),
    (_ctx.isError)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          class: "text-red-700 self-end mb-2",
          icon: ['fas', 'exclamation']
        }))
      : _createCommentVNode("", true)
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AlertDialogVue = _resolveComponent("AlertDialogVue")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_component_AlertDialogVue, {
      text: _ctx.toastQueue?.[0],
      class: "absolute bottom-5 transition -translate-y-10 ease-in-out"
    }, null, 8, ["text"])
  ], 64))
}

import EmptyButtonVue from "@/components/particles/EmptyButton.vue";
import HollowButtonVue from "@/components/particles/HollowButton.vue";
import SolidButtonVue from "@/components/particles/SolidButton.vue";
import UserNavVue from "@/components/molecules/UserNav.vue";
import TextInputWithErrorVue from "@/components/particles/TextInputWithError.vue";

import { defineComponent, ref } from "vue";
import router from "@/router";

export default defineComponent({
  components: {
    EmptyButtonVue,
    HollowButtonVue,
    TextInputWithErrorVue,
    UserNavVue,
    SolidButtonVue,
  },

  setup() {
    let code = ref("");
    let triedSubmit = ref(false);

    // TODO: implement this in a central store
    const getCleanedCode = (val: string): string | null => {
      return val;
    };

    const goToForm = () => {
      triedSubmit.value = true;
      const cleanCode = getCleanedCode(code.value);
      router.push(cleanCode || "");
    };

    return {
      code,
      triedSubmit,
      goToForm,
      getCleanedCode,
    };
  },
});

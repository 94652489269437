
import HollowButtonVue from "@/components/particles/HollowButton.vue";
import SolidButtonVue from "@/components/particles/SolidButton.vue";

import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    HollowButtonVue,
    SolidButtonVue,
  },

  setup() {
    const route = useRoute();
    let q = route.query;

    let statusCode = q.type?.toString() || "404";
    let message = q.message?.toString();

    return {
      statusCode,
      message,
    };
  },
});
